.server-data th,
.server-data tbody td {
    text-align: center;
}


.server-data .-cursor-pointer {
    cursor: pointer;

}

.server-data th .-cursor-pointer.-sort-desc .rt-resizable-header-content::after {
    content: "\F0DD";
    top: -3px;
    position: relative;
    display: inline-block;
    bottom: 0px;
    right: -7px;
    font-family: 'FontAwesome';
    opacity: 1;


}

.server-data th.-cursor-pointer.-sort-asc .rt-resizable-header-content::after {
    content: "\F0DE";
    top: 3px;
    position: relative;
    display: inline-block;
    bottom: 0px;
    right: -7px;
    font-family: 'FontAwesome';
    opacity: 1;

}

.server-data th.-cursor-pointer .rt-resizable-header-content::after {
    opacity: 0.6;
    content: "\F0DC";
    position: relative;
    display: inline-block;
    bottom: 0px;
    right: -7px;
    font-family: 'FontAwesome';

}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}


.MuiDataGrid-columnHeaderDraggableContainer {
    outline: none !important;
}

.css-t89xny-MuiDataGrid-columnHeaderTitle {
    content: "\F0DD";
    top: -3px;
    /* position: relative; */
    display: inline-block;
    bottom: 0px;
    right: -7px;
    font-family: 'FontAwesome';
    opacity: 1;

}

.MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-columnHeaderTitle {
    color: #212529;
    font-weight: bold;
    font-size: 14px;
}

.MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-columnHeaderTitle {
    outline: none !important;
}

/* div.MuiDataGrid-main{ 
    flex: none !important;
    overflow: hidden !important;
} */


.product-name .MuiDataGrid-main .css-axafay-MuiDataGrid-virtualScroller {
    /* overflow: auto; */
    height: 0% !important;
    /* position: relative; */
}

.product-name .MuiDataGrid-main .MuiDataGrid-virtualScroller {
    /* overflow: auto; */
    height: 0% !important;
    /* position: relative; */
}

.MuiDataGrid-row {
    background-color: #b9e4f6 !important;
}

.username-info {
    font-size: 12px;
    font-weight: 900;
    margin: 0px -50px 0px 0px;
}

@media (min-width: 992px) {
    .rowFilter {
        max-width: 10.666667% !important;
    }
}

@media (min-width: 992px) {
    .rowMyFilter {
        max-width: 12.5% !important;
    }
}

.dropdown-menu-custom {
    background-color: #dee2e6 !important;
}

.dropdown-item-custom {
    color: #000 !important;
}

/* Add this CSS to your stylesheets */
/* .dropdown-menu .dropdown-item:hover {
    background-color: transparent !important; 
    color: inherit !important;
    text-decoration: inherit !important; 
    cursor: pointer !important;
  } */
/* MuiDataGrid-columnHeader MuiDataGrid-columnHeader--sortable MuiDataGrid-columnHeader--sorted MuiDataGrid-withBorderColor */
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
    background-color: #D3D3D3 !important;
}

.dropdown-menu .dropdown-item {
    padding: 0px !important;
}

.dropdown-item {
    padding: 0rem !important;
    margin: 0px !important;
    text-align: center !important;
    font-weight: 600 !important;
}

.dropdown-item p {
    margin: 10px;
}

.table-priority-column {

    /* font-weight: bold;
    color: #000; */
    font-weight: 400;
    color: #000;
    line-height: 1.6;
    margin-top: 8px;
}
